import React from 'react'
import {siteName} from "../constants/globalVars"

export default class Contact extends React.Component {

    constructor(props) {
        super(props)
        this.title = `Contact Us | ${siteName}`
    }
    
    
    render() {
        (() => {document.title = this.title;})()
        return (
            <React.Fragment>
                <div className="main">
                    <div className="main-inner margin-top-50">
                        <div className="one-third-left-col">
                            <h1>Contact us</h1>
                            <p className="para">
                                Glen Carne<br />
                                Barkla Shop, St. Agnes,<br />
                                Cornwall TR5 0XN</p>
                            <p className="para">
                                <span className="green-text">t:</span> 01872 554141<br />
                                <span className="green-text">e:</span> info@glencarne.org.uk<br />
                                www.glencarne.org.uk
                            </p>
                            <p className="para">
                                @GlenCarne<br />
                                GlenCarneHousingSupport
                            </p>
                            <h4>
                                Office Hours
                            </h4>
                            <p className="para">
                                Monday – Friday 8am–6pm.<br />Concierge service out-of-hours.
                                <br />Note: during the <strong>Covid-19</strong> pandemic these hours may be subject to change
                            </p>
                            <p>
                            
                            </p>
                            <h4>
                                Parking available
                            </h4>
                            <h4>
                                Directions
                            </h4>
                            <p className="para"> 
                            Located on the B3285 in-between St. Agnes and Perranporth.<br />
                            For detailed directions call 01872 554141.
                            </p>
                            <br /><br />
                            <a href="https://smile.amazon.co.uk/ch/1140893-0" target="_blank"><img src="/media/images/glencarneamazon.png" /></a>
                        </div>    
                        <div className="two-third-right-col">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10191.721045927621!2d-5.18624473950548!3d50.311893507947616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x486b197cba51a32d%3A0x29f948a7c16710a4!2sGlen+Carne!5e0!3m2!1sen!2suk!4v1566415385961!5m2!1sen!2suk" title="Glen Carne Location Map" width="700" height="600" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
                        </div>
                        <div className="clear-fix"></div>
                    </div>    
                </div>
            </React.Fragment>
        )
    }
}