import React from 'react'
import SiteLinks from './site-links'
import { Link } from '@reach/router'
import '../css/header.css'

export default class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      links: [
        {
          name: 'Home',
          href: '/',
          color: 'dark',
        },
        {
          name: 'About Us',
          color: 'green',
          href: '/about-us',
          innerLinks: [
            {
              name: 'Volunteers & Training',
              href: '/volunteers-and-training',
            },
            {
              name: 'The Team & Trustees',
              href: '/the-team-and-trustees',
            },
            {
              name: 'Testimonials',
              href: '/testimonials',
            },
          ],
        },
        {
          name: 'Take a Tour of Glen Carne',
          href: '/housing',
          color: 'dark',
          innerLinks: [
            {
              name: 'Housing',
              href: '/housing',
            },
            {
              name: 'Support',
              href: '/support',
            },
            {
              name: 'Training',
              href: '/training',
            },
            {
              name: 'Team Building and Leisure',
              href: '/team-building-and-leisure',
            },
            {
              name: 'Gallery',
              href: '/our-gallery',
              jumpTo: 'our-gallery',
            },
          ],
        },
        {
          name: 'Application & Eligibility',
          href: '/application-and-eligibility',
          color: 'green',
          innerLinks: [
            {
              name: 'Policies PDF Files and how to apply',
              href: '/policies-and-how-to-apply',
            },
            {
              name: 'Links to helpful resources',
              href: '/links-to-helpful-resources',
            },
          ],
        },
        {
          name: 'Online Book',
          href:
            'https://www.theonlinebookcompany.com/OnlineBooks/GlenCarne/Content/Filler',
          color: 'dark',
          type: 'external',
        },
        {
          name: 'Contact Us',
          href: '/contact-us',
          color: 'green',
        },
      ],
    }
  }

  handleClick = () => {
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <React.Fragment>
        <div className="header-under"></div>
        <header className="header-outer">
          <div className="header">
            <Link to="/" onClick={this.handleClick}>
              <div className="main-logo" path="/">
                <img
                  className="main-logo-img"
                  src="/media/logos/glen-carne-logo.png"
                  alt="Glen Carne Logo"
                />
              </div>
            </Link>
            <nav className="links-container">
              <SiteLinks links={this.state.links} />
            </nav>
            <div className="clear-fix"></div>
          </div>
        </header>
      </React.Fragment>
    )
  }
}
