import React from 'react'
import '../../css/hero.css'

export default class LesserHero extends React.Component {
    render() {
        return(
            <React.Fragment>
                    <div className="hero-container-main-image">
                        <img className="hero-image" alt={this.props.altText} src={this.props.imgSrc} />
                        <div className="hero-container-border-bottom"><img src="/media/decorative/tools-border-white.png" alt="Decorative tools border" /></div>
                    </div>
            </React.Fragment>
        )
    }
}