import React from 'react'
import '../../css/hero.css'
import { Link } from '@reach/router'

export default function Hero() {

    const handleClick = () => {
        window.scrollTo(0, 0);
    }

    return(
        <React.Fragment>
            <div className="hero-container">
                <div className="hero-container-main-image">
                    <img className="hero-image" alt="hands holding small tree" src='/media/homepage/hero-image-full-width.jpg' />
                    <div className="hero-container-border-bottom"><img src="/media/decorative/tools-border-white.png" alt="Decorative tools border" /></div>
                </div>
                
                <div className="hero-text-container">
                    <div className="hero-title">
                        <h1>Supported Housing and<br />Training in Cornwall</h1>
                    </div>
                    <div className="badge badge-left script-font badge-active">
                        <Link className="badge-text" to={"/our-aims/"} onClick={handleClick}>What is<br />Glen<br />Carne?</Link>
                    </div>
                    <div className="badge badge-mid script-font badge-active">
                        <Link className="badge-text" to={"/application-and-eligibility/"} onClick={handleClick}>How do I<br />apply?</Link>
                    </div>
                    <div className="badge badge-right script-font badge-active">
                        <a className="badge-text" href="https://www.theonlinebookcompany.com/OnlineBooks/GlenCarne/Content/Filler" rel="noopener noreferrer"  target="_blank">Online <br />Book</a>
                    </div>        
                </div>
            </div>
        </React.Fragment>
    )
}