import React from 'react'
import '../../css/whats-on.css'
import TwitterFeed from '../dynamic/twitter-feed'

const activityListLeft = [
    "Tenancy accreditation",
    "Rights and responsibilities",
    "Personal budgeting",
    "Independent living skills",
    "Living in the community",
    "Food safety and storage",
    "Fitness/gym induction",
    "IT/computer studies",
    "Horticulture skills",
]
const activityListRight = [
    "Sailing (confidence building)",
    "BF Adventure team building",
    "Bush craft skills",
    "Indian cookery",
    "First aid and defibrillator",
    "Fire safety",
    "Healthy eating",
    "Qi Gong and Tai Chi",
]

export default function WhatsOn() {
    return (
        <React.Fragment>
            <div className="decorative-border-grey">
                <img src="/media/decorative/tools-border-grey.png" alt="Decorative Tools Border" />
            </div>    
            <div className="grey-block large-padding-top-bottom">
                <div className="main-inner">
                    <div className="">
                        <div className="large-grey-text">
                            Over the last 12 months
                        </div>
                    </div>
                    <div className="whats-on">
                        <div className="two-thirds">
                            <div className="inner">
                                <img src="/media/homepage/yacht-pilot.jpg" alt="Yacht Pilot" />
                                <div className="title green-text">
                                    A wide range of training courses including
                                </div>
                                <div className="two-col">
                                    <div className="col">
                                        <ul>
                                            {activityListLeft.map(item =>
                                                <li key={item}>{item}</li>
                                            )}
                                        </ul>
                                    </div>    
                                    <div className="col">
                                    <ul>
                                            {activityListRight.map(item =>
                                                <li key={item}>{item}</li>
                                            )}
                                        </ul>
                                    </div> 
                                    <div className="clear-fix"></div>   
                                </div>   
                            </div>        
                        </div>
                        <div className="one-third">
                            <div className="twitter-header">
                                <div className="inner">
                                    <img src="/media/homepage/twitter-bird.png" alt="Twitter Logo"/>
                                </div>
                            </div>
                            <div className="inner">
                                <TwitterFeed />
                            </div>
                        </div>    
                        <div className="clear-fix"></div>
                    </div>
                </div>    
            </div>   
        </React.Fragment>
    )
}