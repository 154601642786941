import React from 'react'
import { siteName } from '../constants/globalVars'

const Policies = () => {
  ;(() => {
    document.title = `Policies | ${siteName}`
  })()
  return (
    <React.Fragment>
      <div className="main">
        <div className="main-inner margin-top-50">
          <h1>Policies</h1>
          <p>
            All documents below are provided in PDF format which can be read
            through Adobe Acrobat.
          </p>
          <div className="links-row">
            <a
              className="links-inner"
              href="/media/pdf/GlenCarneApplication2020_V2.pdf"
              target="_blank"
            >
              <img src="/media/images/pdf-icon.jpg" alt="pdf-icon" />
              <br />
              <span className="link-company">Application Form</span>
            </a>
            <a
              className="links-inner"
              href="/media/pdf/GCTenancyAccreditation2020.pdf"
              target="_blank"
            >
              <img src="/media/images/pdf-icon.jpg" alt="pdf-icon" />
              <br />
              <span className="link-company">
                Glen Carne Tenancy Accreditation
              </span>
            </a>
            <a
              className="links-inner"
              href="/media/pdf/GlenCarneSupportLeaflet2020.pdf"
              target="_blank"
            >
              <img src="/media/images/pdf-icon.jpg" alt="pdf-icon" />
              <br />
              <span className="link-company">Glen Carne Support Leaflet</span>
            </a>
          </div>

          <div className="links-row">
            <a
              className="links-inner"
              href="/media/pdf/PrivacyPolicy.pdf"
              target="_blank"
            >
              <img src="/media/images/pdf-icon.jpg" alt="pdf-icon" />
              <br />
              <span className="link-company">Privacy Policy</span>
            </a>
          </div>

          <div className="clear-fix"></div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Policies
