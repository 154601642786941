import React from 'react'
// import Dynamic from '../components/dynamic/dynamic'
import Hero from '../components/homepage/hero'
import LesserHero from '../components/homepage/lesser-hero'
import BadgeBox from '../components/homepage/badge-box'
import WhatsOn from '../components/homepage/whats-on'
import FactsAndFigures from '../components/homepage/facts-and-figures'
import ClientTestimonial from '../components/homepage/client-testimonial'
import { Link } from '@reach/router'
// import OurTeam from '../components/homepage/our-team'
import OurGallery from '../components/homepage/our-gallery'
import '../css/main.css'
import '../css/home.css'

export default class Home extends React.Component {
  constructor(props) {
    super(props)
    this.title = 'Glen Carne'
    this.currentValue = 2500
    this.topValue = 50000
  }

  handleClick = () => {
    window.scrollTo(0, 0)
  }

  shouldComponentUpdate() {
    // console.log("HOME component updated")
    return true
  }

  render() {
    ;(() => {
      document.title = this.title
    })()
    return (
      <React.Fragment>
        <Hero />
        <main className="main">
          <div className="main-inner margin-top-50">
            <div className="strapline large-padding-top">
              <span className="green-text large-bold-text">23%</span> of clients
              who moved on from Glen Carne gained employment
            </div>
            <div className="strapline large-padding-top-bottom">
              <span className="green-text large-bold-text">69%</span> of clients
              participated in voluntary work
            </div>
            <div className="home-container-mid green-back">
              <div className="left">
                <img
                  className="left-image"
                  src="/media/homepage/gardening.jpg"
                  alt="Man gardening"
                />
              </div>
              <div className="right">
                <div className="inner align-center">
                  <h2 className="white-text">Our Aims</h2>
                  <div className="underline-white"></div>
                  <p>
                    The charitable objectives of Glen Carne are to relieve need,
                    hardship and distress of disadvantaged people in Cornwall
                  </p>
                  <Link
                    className="white-button green-text bold-text"
                    to={'/our-aims/'}
                    onClick={this.handleClick}
                  >
                    Find our more about us
                  </Link>
                </div>
              </div>
              <div className="clear-fix"></div>
            </div>
            <div className="clear-fix"></div>
            <div className="large-grey-text">How we work</div>
            <div className="clear-fix"></div>
            <BadgeBox />
          </div>
          <WhatsOn />
          <div className="hero-container">
            <LesserHero
              imgSrc="/media/homepage/tent-image.jpg"
              altText="Men in Tent"
            />
            <div className="tent-text-container">
              <div className="large">92%</div>
              <div className="small">of clients participated in training</div>
            </div>
          </div>

          <FactsAndFigures />

          <ClientTestimonial />

          <div id="our-gallery"></div>
          <OurGallery />

          <div className="hero-container">
            <div className="hero-container-main-image">
              <img
                className="hero-image"
                alt="Thoughtful man"
                src="/media/homepage/contemplation.jpg"
              />
              <div className="thoughtful-caption">
                &ldquo;If it wasn&rsquo;t for the help and support of everyone
                at Glen Carne, I honestly don&rsquo;t know where I would be
                today, if at all&rdquo;
              </div>
            </div>
          </div>

          <div className="main-inner">
            <div className="home-container-mid light-blue-back">
              <div className="left">
                <div className="large-number">33</div>
                <div className="large-number-under">bed-spaces</div>
              </div>
              <div className="left">
                <div className="large-number">91</div>
                <div className="large-number-under">
                  applications over the year
                </div>
              </div>
              <div className="clear-fix"></div>
            </div>
          </div>

          <div className="hero-container lesser-hero">
            <LesserHero
              margin="70px auto"
              imgSrc="/media/homepage/boatman.jpg"
              altText="Man on boat"
            />
          </div>

          <div className="main-inner">
            <div className="home-container-mid green-back">
              <div className="left flex-center flex-columns">
                <div className="large-number-title">
                  Time
                  <br />
                  Credits
                </div>
                <div className="large-number-under">volunteering</div>
              </div>
              <div className="left">
                <div className="blue-paragraph">
                  Working in partnership with Tempo Time Credits provides
                  voluntary work opportunities both on and off-site. These
                  opportunities are closely linked to the Horticulture courses
                  provided by Duchy College, which include planting/potting,
                  grounds maintenance etc.
                </div>
                <div className="blue-paragraph">
                  Other voluntary opportunities are provided within the local
                  community, which range from animal care to gardening at the
                  Eden Project.
                </div>
              </div>
              <div className="clear-fix"></div>
            </div>
          </div>
        </main>
      </React.Fragment>
    )
  }
}
