import React from 'react'
import { siteName } from '../constants/globalVars'

const Links = () => {
  ;(() => {
    document.title = `Links to Helpful Resources | ${siteName}`
  })()
  return (
    <React.Fragment>
      <div className="main">
        <div className="main-inner margin-top-50">
          <h1>Links to helpful resources</h1>
          <div className="links-outer">
            <div className="links-row">
              <a
                className="links-inner"
                href="https://www.cornwallhousing.org.uk/find-a-home/homelessness-reduction/emergency-and-temporary-accommodation/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="link-company">
                  Cornwall Council Emergency and temporary accommodation
                </span>
                Cornwall Council Housing Options provides advice to people who
                are homeless or at risk of becoming homeless and to discuss
                their housing options. Call: 0300 1234 161.
              </a>
              <a
                className="links-inner"
                href="http://www.stpetrocs.org.uk"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="link-company">St Petroc’s Society</span>
                Cornish charity providing homelessness outreach, advice, support
                and accommodation, including a hospital discharge scheme and
                cold weather provision. Call: 01872 264153.
              </a>
              <a
                className="links-inner"
                href="https://www.coastlinehousing.co.uk/homeless-service"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="link-company">
                  Coastline Homeless Service, including Day Centre and Crisis
                  Accommodation
                </span>
                Crisis and supported accommodation in Pool, Redruth and Camborne
                for homeless and vulnerably housed adults. A day centre is
                available for further information, advice, referrals, and
                activities. Call: 01209 200086.
              </a>
              <a
                className="links-inner"
                href="http://www.cosgarnehall.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link-company">
                  Harbour Housing - Cosgarne Hall
                </span>
                Cosgarne Hall provides supported housing for those with varying
                needs, including addiction and mental health issues.
              </a>
              <a
                className="links-inner"
                href="https://chaosgroupcornwall.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link-company">
                  CHAOS (Community Helping All Of Society)
                </span>
                CHAOS delivers a number of community based services within
                Cornwall, including a café, farm and other services.
              </a>
              <a
                className="links-inner"
                href="http://www.crisis.org.uk"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="link-company">Crisis</span>
                National charity for homeless people providing housing advice,
                research and campaigning.
              </a>
              <a
                className="links-inner"
                href="https://capuk.org/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="link-company">
                  Christians Against Poverty (CAP)
                </span>
                CAP is a free national organisation providing debt counselling
                for people in financial difficulty. It also provides Job Clubs
                for those seeking employment and Fresh Start Courses for people
                looking to overcome addictions and dependencies.
              </a>

              <a
                className="links-inner"
                href="http://www.shelter.org.uk"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="link-company">Shelter</span>
                National charity providing campaigning and advice on homeless
                issues. Call: 0344 515 2300.
              </a>
            </div>
            <div className="links-row">
              <a
                className="links-inner"
                href="http://www.gov.uk"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="link-company">www.gov.uk</span>
                The new place to find government services, benefits, crisis
                loans, housing and other information.
              </a>
              <a
                className="links-inner"
                href="https://safercornwall.co.uk/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="link-company">Safer Cornwall</span> A
                partnership of public, voluntary, community and private
                organisations working together to make Cornwall’s communities
                safer. Call: 0300 1234 232.
              </a>
              <a
                className="links-inner"
                href="https://www.cornwall.gov.uk/health-and-social-care/adult-social-care/safeguarding-adults/information-for-the-public/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="link-company">Safeguarding Adults</span>
                If you are an adult experiencing abuse or neglect call 0300 1234
                131 or email accessteam.referral@ cornwall.gov.uk . You can also
                contact the Safeguarding adults team if you are concerned about
                an adult who may be experiencing abuse or neglect.
              </a>
              <a
                className="links-inner"
                href="https://www.wearewithyou.org.uk/services/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link-company">we are with you</span>
                Wide range of drug and alcohol support services locally and
                nationwide.
              </a>
            </div>
            <div className="links-row">
              <a
                className="links-inner"
                href="https://www.supportincornwall.org.uk/kb5/cornwall/directory/home.page"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link-company">Support in Cornwall</span>
                Online directory of services for residents of Cornwall requiring
                care and support, including youth, adult and family information.
              </a>
              <a
                className="links-inner"
                href="https://truro.foodbank.org.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link-company">Truro Foodbank</span>
                The Truro foodbank is a project founded by local churches and
                community groups providing food parcels for those in
                emergencies. Call: 077 2171 1669
              </a>
              <a
                className="links-inner"
                href="https://www.cornwall.gov.uk/health-and-social-care/mental-health/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link-company">
                  Cornwall Mental Health Support
                </span>
                Details of support service for those experiencing mental health
                issues, including anxiety, depression or suicidal thoughts.
              </a>
              <a
                className="links-inner"
                href="https://www.duchy.ac.uk/location-pages/rosewarne-campus.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link-company">Duchy College (Rosewarne)</span>
                Provides accredited horticulture training at Glen Carne and on
                site at Rosewarne.
              </a>
            </div>
            <div className="links-row">
              <a
                className="links-inner"
                href="http://www.qacic.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link-company">
                  Questions &amp; Answers CIC
                </span>
                Provides accreditation and funding for bespoke courses at Glen
                Carne and also helped develop Glen Carne’s Tenancy
                Accreditation.
              </a>
              <a
                className="links-inner"
                href="http://www.switchcommunity.co.uk"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="link-company">SWITCH Community Ltd </span>
                Provides computer studies and a variety of other classes for
                Glen Carne. These include business start up, assertiveness
                skills, a range of soft skills and money management.
              </a>
            </div>
            <div className="links-row"></div>
            <div className="clear-fix"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Links
