import React from 'react'

function renderMen(amount) {
    const items = [];
    for (var i=0; i < amount; i++) {
        items.push(<img key={`man` + i} className="man-symbol" src="/media/homepage/man-symbol.png" alt="man symbol" />);
    }
    return items;
}

const FactsAndFigures = () => {
    return (
        <div className="main-inner">
            <div className="home-container-mid">
                <div className="flex-three-column">
                    <div className="flex-column-third dark-blue-back rounded-corners">
                        <div className="inner white-text">
                            <h3 className="bold-text white-text percentage-text">69%</h3>
                            <p className="medium-text no-margin no-padding">of clients participated in <b>voluntary work</b></p><br />
                            {renderMen(34)}
                        </div>
                    </div>
                    <div className="flex-column-third purple-back rounded-corners">
                        <div className="inner white-text">
                            <h3 className="bold-text white-text percentage-text">92%</h3>
                            <p className="medium-text no-margin no-padding">of clients participated in <b>training</b></p><br />
                            {renderMen(46)}
                        </div>
                    </div>
                    <div className="flex-column-third dark-green-back rounded-corners">
                        <div className="inner white-text">
                            <h3 className="bold-text white-text percentage-text">23%</h3>
                            <p className="medium-text no-margin no-padding">of clients who moved on from Glen Carne gained <b>employment</b></p> <br />
                            <div className="align-center">
                                <img src="/media/homepage/man-silhouette.png" alt="man silhouette" />
                            </div>    
                        </div>
                    </div>
                </div>
            </div>    
        </div> 
    )
}
export default FactsAndFigures