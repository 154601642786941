import React from 'react'
import { siteName } from '../constants/globalVars'

const Training = () => {
  ;(() => {
    document.title = `Training | ${siteName}`
  })()
  return (
    <div className="main">
      <div className="main-inner margin-top-50">
        <div className="header-full-width">
          <h1 className="other-pages-h1">Training</h1>
        </div>

        <div className="clear-fix"></div>
        <div className="other-pages-image-container">
          <h5 className="padding-20">
            Glen Carne provides a wealth of training opportunities for
            residents. Courses are available to help residents develop skills
            and gain possible future employment. 
          </h5>
        </div>

        <div className="other-pages-image-container grey-block">
          <div className="right-image">
            <img
              className="imagePlacer block-image"
              src="/media/images/support-01.jpg"
              alt="support at Glen Carne"
            />
          </div>
          <div className="left-text-outer">
            <div className="left-text-inner black-text">
              Training rooms are available to allow external colleges and
              approved training providers to deliver courses on-site. Courses
              include first-aid, health and safety, teambuilding, literacy and
              numeracy support, IT, outdoor pursuits and many others. More
              specific courses such as HGV operations, fork lift licences, care
              work, and certain voluntary work opportunities are sourced
              individually with external training providers for those who would
              like to develop their work skills further. Glen Carne also
              provides a Tenancy accredited scheme developed with{' '}
              <a
                href="https://www.qacic.co.uk/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Questions &amp; Answers (QACIC)
              </a>
              .
            </div>
          </div>
          <div className="clear-fix"></div>
        </div>

        <div className="flex-row-container">
          <img
            className=""
            src="/media/training/training01.jpg"
            alt="support at Glen Carne"
          />
          <img
            className=""
            src="/media/training/training02.jpg"
            alt="training at Glen Carne"
          />
        </div>

        <div className="clear-fix"></div>
        <div className="other-pages-image-container">
          <p className="para-large padding-20 medium-font">
            Glen Carne also operates an innovative horticulture course in
            association with{' '}
            <a
              href="https://www.duchy.ac.uk/location-pages/rosewarne-campus.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Duchy College Rosewarne
            </a>
            , which allows opportunities for voluntary work and employment
            within the horticulture industry.
          </p>
          <p className="para-large padding-20 medium-font">
            The course is based between Glen Carne or Duchy College Rosewarne,
            depending upon demand.
          </p>
        </div>

        <div className="flex-row-container">
          <img
            className=""
            src="/media/training/glen-carne-duchy-leaflet.jpg"
            alt="support at Glen Carne"
          />
          <img
            className=""
            src="/media/training/glen-carne-work-preparation-scheme.jpg"
            alt="training at Glen Carne"
          />
        </div>
      </div>
    </div>
  )
}

export default Training
