import React from 'react'
import '../../css/client-testimonial.css'
import { Link } from '@reach/router'

export default function ClientTestimonial() {
    const handleClick = () => {
        window.scrollTo(0,0)
    }
    return (
        <React.Fragment>
            <div className="decorative-border-grey">
                <img src="/media/decorative/tools-border-grey.png" alt="Decorative Tools Border" />
            </div>
            <div className="grey-block large-padding-top-bottom">
                <div className="main-inner">
                    <div className="">
                        <div className="large-grey-text">
                            Client Testimonial
                        </div>
                    </div>
                    <div className="home-container-mid overflow-hidden wheelchair-background-color">
                        <img className="absolute-image-stretch-left-right wheelchair-desktop-only" src="/media/homepage/wheelchair-image.jpg" alt="wheelchair background" />
                         <div className="client-testimonial-container">
                            <div className="client-testimonial-header">
                                I’d like to thank all the people
                                involved in the creation and
                                support of Glen Carne, I cannot
                                state strongly enough how the
                                support of this amazing place
                                literally saved my life.
                            </div>
                            <div className="client-testimonial-text">
                                <p>
                                    When I came across this charity I was at the lowest point
                                    in my life, my health physical and mental was very bad,
                                    my disability of 20 years of constant pain had become
                                    unbearable and I’d lost my home my business and my
                                    family and become homeless.
                                </p>
                                <p>   
                                    Glen Carne was a safe place with empathetic caring
                                    staff who enabled me to get good medical support help
                                    with benefits and put a roof over my head in a beautiful
                                    environment.
                                </p>
                                <p>
                                    I felt that after two years living in a van with no
                                    interaction or support I had no future at all. I was just
                                    about existing, without the support of Glen Carne I really
                                    don’t think I’d still be here.
                                </p>
                                <p>
                                    I have now started to rebuild my life and have got
                                    housing and I hope after surgery I will be able to return
                                    to work. I can’t state strongly enough that without
                                    Andy, Bob and the team with their altruistic vision many
                                    vulnerable men like myself would not be here.
                                </p>
                                <p>
                                    I can’t thank you all enough for the genuine empathy I
                                    found at Glen Carne and the support to rebuild my life.
                                </p>
                            </div>
                            <br />
                            <Link className="client-testimonial-button bold-text" to={"/testimonials/"} onClick={handleClick}>
                                View more testimonials ...
                            </Link>
                        </div>
                        <div className="clear-fix"></div>
                    </div>
                </div>    
            </div> 
        </React.Fragment>        
    )
}