import React from 'react'
import '../css/footer.css'

export default class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="decorative-border-grey">
          <img
            src="/media/decorative/tools-border-green.png"
            alt="Decorative Tools Border"
          />
        </div>
        <footer className="footer">
          <div className="normal">t/f: 01872 554141 (support)</div>
          <div className="normal">t/f: 01872 554022 (operations)</div>
          <div className="normal">e: info@glencarne.org.uk</div>
          <div className="normal">Barkla Shop, St. Agnes, Cornwall TR5 0XN</div>
          <div className="large">www.glencarne.org.uk</div>
          <div className="small">Registered charity number: 1140893</div>
          <div className="small">
            Company registered by guarantee. Company number: 7529092
          </div>
        </footer>
      </React.Fragment>
    )
  }
}
